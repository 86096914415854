<template>
  <footer ref="contact">
    <div class="footer-infos">
      <h2>{{ $t("message.welcome.enterContact") }}</h2>
      <div class="footer-contact">
        <div class="contact-email">
          <b-img
            src="@/assets/images/pages/welcome/icon-email.svg"
            alt="analitycs BRXMIND"
          />
          <h5>{{ $t("message.welcome.mail") }}</h5>
          <p>contato@braxxy.com.br</p>
        </div>
        <div class="contact-phone">
          <b-img
            src="@/assets/images/pages/welcome/icon-phone.svg"
            alt="analitycs BRXMIND"
          />
          <h5>{{ $t("message.welcome.phone") }}</h5>
          <p>+55 11 3090-6629</p>
        </div>
        <div class="contact-linkedin">
          <b-img
            src="@/assets/images/pages/welcome/icon-locale.svg"
            alt="analitycs BRXMIND"
          />
          <h5>{{ $t("message.welcome.locale") }}</h5>
          <p style="width: 160px; text-align: center">
            Avenida Sagitário, nº 138 - Sala 409 - Alphaville, Barueri/SP
          </p>
        </div>
      </div>
      <div class="footer-social">
        <div>
          <b-link
            href="https://www.linkedin.com/company/braxxy-software-projetos"
            target="_blank"
          >
            <b-img
              src="@/assets/images/pages/welcome/social-linkedin.svg"
              alt="analitycs BRXMIND"
            />
          </b-link>

          <b-link href="https://www.braxxy.com.br" target="_blank">
            <b-img
              src="@/assets/images/pages/welcome/icon-site.svg"
              alt="analitycs BRXMIND"
            />
          </b-link>
          <b-link
            href="https://www.youtube.com/channel/UC-Z-QuAHLXG3q2wwZBcW0hQ"
            target="_blank"
          >
            <b-img
              src="@/assets/images/pages/welcome/social-yt.svg"
              alt="analitycs BRXMIND"
            />
          </b-link>

          <b-link href="mailto:contato@braxxy.com.br" target="_blank">
            <b-img
              src="@/assets/images/pages/welcome/social-email.svg"
              alt="analitycs BRXMIND"
            />
          </b-link>
        </div>
      </div>
    </div>

    <div class="copyright">
      <p>
        <b-link class="text-white" target="_blank" href="/PrivacyPolicy">
          {{ $t("message.welcome.privacyPolicy") }}</b-link
        >
      </p>
      <p>@Braxxy - {{ $t("message.welcome.allDirectsReserveds") }}</p>
      <!-- <p>
        <b-link class="text-white" target="_blank" href="/TermsAndConditions">{{
          $t("message.welcome.termsAndConditions")
        }}</b-link>
      </p> -->
    </div>
  </footer>
</template>

<script>
import { BImg, BLink } from "bootstrap-vue";

export default {
  components: {
    BLink,
    BImg,
  },

  methods: {
    scrollMeTo(refName) {
      var element = this.$refs[refName];
      var top = element.offsetTop;
      window.scrollTo(0, top);
    },
  },
};
</script>

<style>
footer {
  padding-top: 8%;
}

.footer-infos h2 {
  width: 256px;
  font-style: normal;
  font-weight: 800;
  font-size: 26px;
  line-height: 39px;
  text-align: center;
  color: #5e5873;
  margin: 0 auto;
}

.footer-contact {
  display: flex;
  justify-content: space-evenly;
  padding-top: 3%;
  flex-wrap: wrap;
}

.footer-contact h5 {
  justify-content: center;
  display: flex;
  font-weight: 800;
  padding-top: 10px;
  flex-wrap: wrap;
}

.footer-contact img {
  margin: 0 auto;
  justify-content: center;
  display: flex;
}
.footer-social {
  text-align: center;
  padding-top: 2%;
  padding-bottom: 10px;
  flex-wrap: wrap;
}

.footer-social img {
  padding-right: 2%;
}

.footer-infos {
  width: 100%;
  height: 100%;
  /* background: #06102d; */
  -webkit-box-shadow: 0 10px 40px -5px rgba(130, 34, 210, 0.75);
  box-shadow: 0 10px 40px -5px rgba(130, 34, 210, 0.75);

  padding-top: 2%;
}

.copyright {
  background: linear-gradient(270deg, #a07093 0%, #41078b 100%);
  height: 5vh;
  height: auto;
  display: flex;
  justify-content: space-evenly;
  padding-top: 10px;
  color: #fff;
  flex-wrap: wrap;
}

.content-contact_icons {
  color: #f8efed;
  background-color: #686096;
  border-radius: 3rem 12rem 12rem 5rem;

  height: 1.3rem;
}

.box {
  display: table-cell;
  text-align: center;
  vertical-align: middle;
}

.content-footer_google {
  margin: 10rem 0 0 15rem;
}

.content-google_maps {
  height: 200px !important;
  width: 50vw !important;
  margin-left: 10rem !important;
}

.content-contact_socialNetworks {
  width: 45px;
  margin-right: 2rem;
}

.content-contact_link ul {
  list-style-type: none;
  margin-top: -3rem;
  line-height: 2.5rem;
  color: #595a53;
  font-size: 1.3rem;
  font-weight: bold;
}

@media screen and (max-width: 767px) {
  .box {
    display: block;
    width: 100%;
  }

  .content-contact_aboutUs {
    width: auto;
  }
  .box h2 {
    margin-top: 3rem;
  }
  .content-footer_google {
    margin: 5rem 0 5rem 0;
  }
  .content-google_maps {
    display: inline flow-root list-item;
  }
}
</style>
