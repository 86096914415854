<template>
  <div>
    <header>
      <div class="nav-menu">
        <nav class="d-flex justify-content-between nav-bar">
          <div class="logo">
            <div class="d-flex nav-logo">
              <vuexy-logo />
              <div>
                <h2 class="brand-text text-primary r-text">BRXMIND</h2>
                <h2 class="brand-text-pp">Braxxy Software e Projetos</h2>
              </div>
            </div>
          </div>

          <div class="nav-bar-items" id="nav">
            <div id="nav-mobile">
              <ul>
                <li>
                  <b-nav-item-dropdown
                    id="dropdown-grouped"
                    variant="link"
                    class="dropdown-language"
                    right
                  >
                    <template #button-content>
                      <b-img
                        :src="currentLocale.img"
                        height="14px"
                        width="22px"
                        :alt="currentLocale.locale"
                      />
                      <span class="ml-50 text-body">{{
                        currentLocale.name
                      }}</span>
                    </template>
                    <b-dropdown-item
                      v-for="localeObj in locales"
                      :key="localeObj.locale"
                      @click="$i18n.locale = localeObj.locale"
                    >
                      <b-img
                        :src="localeObj.img"
                        height="14px"
                        width="22px"
                        :alt="localeObj.locale"
                      />
                      <span class="ml-50">{{ localeObj.name }}</span>
                    </b-dropdown-item>
                  </b-nav-item-dropdown>
                </li>
                <li>
                  <b-link
                    @click="goToLogin()"
                    class="nav-menu_text nav-menu_login"
                  >
                    Login
                  </b-link>
                </li>
              </ul>
            </div>
            <button @click="showMenu()" id="btn-mobile"></button>
          </div>
        </nav>
      </div>
    </header>

    <div class="privacy-mobile">
      <div class="privacy-text">
        <h1 class="mb-2">{{ $t("message.privacyPolicy.privacyPolicies") }}</h1>
        <p></p>
      </div>
      <div class="privacy-text">
        <h2>{{ $t("message.privacyPolicy.questionOne") }}</h2>
        <ul class="mt-5">
          <li>
            <h5>
              <strong class="brand-text text-primary privacy-text_topics"
                >a</strong
              >
              {{ $t("message.privacyPolicy.questionOnea") }}
            </h5>
            <ol>
              <li>
                {{ $t("message.privacyPolicy.questionOnea1") }}
              </li>
              <li>
                {{ $t("message.privacyPolicy.questionOnea2") }}
              </li>
            </ol>
          </li>
          <li class="mt-5">
            <h5>
              <strong class="brand-text text-primary privacy-text_topics"
                >b</strong
              >
              {{ $t("message.privacyPolicy.questionOneb") }}
            </h5>
            <ol>
              <li>
                {{ $t("message.privacyPolicy.questionOneb1") }}
              </li>
              <li>
                {{ $t("message.privacyPolicy.questionOneb2") }}
              </li>
              <li>
                {{ $t("message.privacyPolicy.questionOneb3") }}
              </li>
              <li>
                {{ $t("message.privacyPolicy.questionOneb4") }}
              </li>
              <li>
                {{ $t("message.privacyPolicy.questionOneb5") }}
              </li>
              <li>
                {{ $t("message.privacyPolicy.questionOneb6") }}
              </li>
              <li>
                {{ $t("message.privacyPolicy.questionOneb7") }}
              </li>
            </ol>
          </li>
        </ul>
      </div>
      <div class="privacy-text">
        <h2>2. {{ $t("message.privacyPolicy.questionTwo") }}</h2>
        <ul class="mt-5">
          <li>
            <h5>
              <p>
                {{ $t("message.privacyPolicy.questionTwo1") }}
              </p>
            </h5>
            <ol>
              <li>
                {{ $t("message.privacyPolicy.questionTwo2") }}
              </li>
              <li>
                {{ $t("message.privacyPolicy.questionTwo2") }}
              </li>
            </ol>
          </li>
        </ul>
      </div>
      <div class="privacy-text">
        <h2>3. {{ $t("message.privacyPolicy.questionThree") }}</h2>
        <ul class="mt-5">
          <p>
            {{ $t("message.privacyPolicy.questionParagraph") }}
          </p>
        </ul>
      </div>
      <div class="privacy-text">
        <h2>4. {{ $t("message.privacyPolicy.questionFour") }}</h2>
        <ul class="mt-5">
          <p>
            {{ $t("message.privacyPolicy.questionFourParagraph") }}
          </p>
          <ol>
            <li>
              {{ $t("message.privacyPolicy.questionFour1") }}
            </li>
            <li>
              {{ $t("message.privacyPolicy.questionFour2") }}
            </li>
            <li>
              {{ $t("message.privacyPolicy.questionFour3") }}
            </li>
            <li>
              {{ $t("message.privacyPolicy.questionFour4") }}
            </li>
            <li>
              {{ $t("message.privacyPolicy.questionFour5") }}
            </li>
            <li>
              {{ $t("message.privacyPolicy.questionFour6") }}
            </li>
            <li>
              {{ $t("message.privacyPolicy.questionFour7") }}
            </li>
            <li>
              {{ $t("message.privacyPolicy.questionFour8") }}
            </li>
          </ol>
        </ul>
      </div>
      <div class="privacy-text">
        <h2>5. {{ $t("message.privacyPolicy.questionFive") }}</h2>
        <ul class="mt-5">
          <p>
            {{ $t("message.privacyPolicy.questionFiveParagraph") }}
          </p>
          <ol>
            <li>E-mail: contato@braxxy.com.br.</li>
            <li>Telefone: +55 11 3090-6629.</li>
          </ol>
        </ul>
      </div>
      <div class="privacy-text">
        <h2>6. {{ $t("message.privacyPolicy.questionSix") }}</h2>
        <ul class="mt-5">
          <p>
            {{ $t("message.privacyPolicy.questionSixParagraph") }}
          </p>
          <ol>
            <li>
              {{ $t("message.privacyPolicy.questionSix1") }}
            </li>
            <li>
              {{ $t("message.privacyPolicy.questionSix2") }}
            </li>
            <li>
              {{ $t("message.privacyPolicy.questionSix3") }}
            </li>
            <li>
              {{ $t("message.privacyPolicy.questionSix4") }}
            </li>
          </ol>
          <p></p>
          <p>
            {{ $t("message.privacyPolicy.questionSixParagraph2") }}
          </p>
          <p>
            {{ $t("message.privacyPolicy.questionSixParagraph3") }}
          </p>
        </ul>
      </div>
      <div class="privacy-text">
        <h2>7. {{ $t("message.privacyPolicy.questionSeven") }}</h2>
        <ul class="mt-5">
          <p>
            {{ $t("message.privacyPolicy.questionSevenParagraph1") }}
          </p>
          <p>
            {{ $t("message.privacyPolicy.questionSevenParagraph2") }}
          </p>
          <p>
            {{ $t("message.privacyPolicy.questionSevenParagraph3") }}
          </p>
          <ol>
            <li>
              {{ $t("message.privacyPolicy.questionSevenOne") }}
            </li>
            <li>
              {{ $t("message.privacyPolicy.questionSevenTwo") }}
            </li>
            <li>
              {{ $t("message.privacyPolicy.questionSevenThree") }}
            </li>
          </ol>
          <p></p>
          <p>
            {{ $t("message.privacyPolicy.questionSevenParagraph4") }}
          </p>
          <p>
            {{ $t("message.privacyPolicy.questionSevenParagraph5") }}
          </p>
        </ul>
      </div>
      <div class="privacy-text">
        <h2>8. {{ $t("message.privacyPolicy.questionEighteen") }}</h2>
        <ul class="mt-5">
          <p>
            {{ $t("message.privacyPolicy.questionEighteenParagraph1") }}
          </p>
          <p>
            {{ $t("message.privacyPolicy.questionEighteenParagraph2") }}
          </p>
          <ol>
            <li>
              {{ $t("message.privacyPolicy.questionEighteenOne") }}
            </li>
            <li>
              {{ $t("message.privacyPolicy.questionEighteenTwo") }}
            </li>
            <li>
              {{ $t("message.privacyPolicy.questionEighteenThree") }}
            </li>
          </ol>
        </ul>
      </div>
      <div class="privacy-text">
        <h2>9. {{ $t("message.privacyPolicy.questionNine") }}</h2>
        <ul class="mt-5">
          <p>
            {{ $t("message.privacyPolicy.questionNineParagraph1") }}
          </p>
          <p>
            {{ $t("message.privacyPolicy.questionNineParagraph2") }}
          </p>
          <p>
            {{ $t("message.privacyPolicy.questionNineParagraph3") }}
          </p>
        </ul>
      </div>
      <div class="privacy-text">
        <h2>10. {{ $t("message.privacyPolicy.questionTeen") }}</h2>
        <ul class="mt-5">
          <p>
            {{ $t("message.privacyPolicy.questionTeenParagraph1") }}
          </p>
          <p>
            {{ $t("message.privacyPolicy.questionTeenParagraph2") }}
          </p>
          <p>
            {{ $t("message.privacyPolicy.questionTeenParagraph4") }}
          </p>
          <p>
            {{ $t("message.privacyPolicy.questionTeenParagraph3") }}
          </p>
          <ol>
            <li>
              {{ $t("message.privacyPolicy.questionTeenOne") }}
              <a
                href="https://support.microsoft.com/pt-br/help/17442/windows-internet-explorer-delete-manage-cookies"
                target="_blank"
                rel="noreferrer noopener"
                >Internet Explorer</a
              >.
            </li>
            <li>
              {{ $t("message.privacyPolicy.questionTeenTwo") }}
              <a
                href="https://support.mozilla.org/pt-BR/kb/gerencie-configuracoes-de-armazenamento-local-de-s"
                target="_blank"
                rel="noreferrer noopener"
                >Firefox</a
              >.
            </li>
            <li>
              {{ $t("message.privacyPolicy.questionTeenThree") }}
              <a
                href="https://support.apple.com/pt-br/guide/safari/sfri11471/mac"
                target="_blank"
                rel="noreferrer noopener"
                >Safari</a
              >.
            </li>
            <li>
              {{ $t("message.privacyPolicy.questionTeenFour") }}
              <a
                href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DDesktop&amp;oco=1&amp;hl=pt-BR"
                target="_blank"
                rel="noreferrer noopener"
                >Google Chrome</a
              >.
            </li>
            <li>
              {{ $t("message.privacyPolicy.questionTeenFive")
              }}<a
                href="https://support.microsoft.com/pt-br/help/4027947/microsoft-edge-delete-cookies"
                target="_blank"
                rel="noreferrer noopener"
                >Microsoft Edge</a
              >.
            </li>
            <li>
              {{ $t("message.privacyPolicy.questionTeenSix") }}
              <a
                href="https://help.opera.com/en/latest/web-preferences/#cookies"
                target="_blank"
                rel="noreferrer noopener"
                >Opera</a
              >.
            </li>
          </ol>
        </ul>
      </div>
      <div class="privacy-text">
        <h2>11. Alteração desta Política de Privacidade</h2>
        <ul class="mt-5">
          <p>
            A atual versão da Política de Privacidade foi formulada e atualizada
            pela última vez em: (data da última atualização da Política de
            Privacidade).
          </p>
          <p>
            Reservamos o direito de modificar essa Política de Privacidade a
            qualquer tempo, principalmente em função da adequação a eventuais
            alterações feitas em nosso site ou em âmbito legislativo.
            Recomendamos que você a revise com frequência.
          </p>
          <p>
            Eventuais alterações entrarão em vigor a partir de sua publicação em
            nosso site e sempre lhe notificaremos acerca das mudanças ocorridas.
          </p>
          <p>
            Ao utilizar nossos serviços e fornecer seus dados pessoais após tais
            modificações, você as consente.
          </p>
        </ul>
      </div>
      <div class="privacy-text">
        <h2>12. Responsabilidade</h2>
        <ul class="mt-5">
          <p>
            A BRXMIND prevê a responsabilidade dos agentes que atuam nos
            processos de tratamento de dados, em conformidade com os artigos 42
            ao 45 da Lei Geral de Proteção de Dados.
          </p>
          <p>
            Nos comprometemos em manter esta Política de Privacidade atualizada,
            observando suas disposições e zelando por seu cumprimento.
          </p>
          <p>
            Além disso, também assumimos o compromisso de buscar condições
            técnicas e organizativas seguramente aptas a proteger todo o
            processo de tratamento de dados.
          </p>
          <p>
            Caso a Autoridade Nacional de Proteção de Dados exija a adoção de
            providências em relação ao tratamento de dados realizado pela
            BRXMIND, comprometemo-nos a segui-las.
          </p>
        </ul>
      </div>
      <div class="privacy-text">
        <h2>13. Isenção de responsabilidade</h2>
        <ul class="mt-5">
          <p>
            Conforme mencionado no Tópico 6, embora adotemos elevados padrões de
            segurança a fim de evitar incidentes, não há nenhuma página virtual
            inteiramente livre de riscos. Nesse sentido, a BRXMIND não se
            responsabiliza por:
          </p>
          <ol>
            <li>
              Quaisquer consequências decorrentes da negligência, imprudência ou
              imperícia dos usuários em relação a seus dados individuais.
              Garantimos e nos responsabilizamos apenas pela segurança dos
              processos de tratamento de dados e do cumprimento das finalidades
              descritas no presente instrumento. Destacamos que a
              responsabilidade em relação à confidencialidade dos dados de
              acesso é do usuário.
            </li>
            <li>
              Ações maliciosas de terceiros, como ataques de hackers, exceto se
              comprovada conduta culposa ou deliberada da BRXMIND. Destacamos
              que em caso de incidentes de segurança que possam gerar risco ou
              dano relevante para você ou qualquer um de nossos
              usuários/clientes, comunicaremos aos afetados e a Autoridade
              Nacional de Proteção de Dados sobre o ocorrido e cumpriremos as
              providências necessárias.
            </li>
            <li>
              Inveracidade das informações inseridas pelo usuário/cliente nos
              registros necessários para a utilização dos serviços da BRXMIND;
              quaisquer consequências decorrentes de informações falsas ou
              inseridas de má-fé são de inteiramente responsabilidade do
              usuário/cliente.
            </li>
          </ol>
        </ul>
      </div>
      <div class="privacy-text">
        <h2>14. Encarregado de Proteção de Dados</h2>
        <ul class="mt-5">
          <p>
            A BRXMIND disponibiliza os seguintes meios para que você possa
            entrar em contato conosco para exercer seus direitos de titular:
          </p>
          <ol>
            <li>E-mail: contato@braxxy.com.br.</li>
            <li>Telefone: +55 11 3090-6629.</li>
          </ol>
        </ul>
      </div>
    </div>
    <div>
      <WelcomeFooter />
    </div>
  </div>
</template>

<script>
import VuexyLogo from "@core/layouts/components/Logo.vue";
import WelcomeFooter from "./WelcomeFooter.vue";

import {
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
  BNavItemDropdown,
  BSidebar,
  BButton,
  BLink,
  BImg,
} from "bootstrap-vue";
export default {
  components: {
    VuexyLogo,
    WelcomeFooter,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    BNavItemDropdown,
    BSidebar,
    BButton,
    BLink,
    BImg,
  },

  computed: {
    currentLocale() {
      return this.locales.find((l) => l.locale === this.$i18n.locale);
    },
  },

  setup() {
    /* eslint-disable global-require */
    const locales = [
      {
        locale: "en",
        img: require("@/assets/images/flags/en.png"),
        name: "Ingles",
      },
      {
        locale: "es",
        img: require("@/assets/images/flags/es.png"),
        name: "Espanhol",
      },
      {
        locale: "pt",
        img: require("@/assets/images/flags/pt.png"),
        name: "Português",
      },
    ];
    /* eslint-disable global-require */

    return {
      locales,
    };
  },

  methods: {
    showMenu() {
      const btn = document.querySelector("#btn-mobile");
      const nav = document.querySelector("#nav");
      nav.classList.toggle("nav-bar-items");
    },

    scrollMeTo(refName) {
      var element = this.$refs[refName];
      var top = element.offsetTop;
      window.scrollTo(0, top);
    },
    goToLogin() {
      this.$router.push({ name: "auth-login" });
    },
  },
};
</script>

<style>
ul {
  list-style-type: none;
}

.privacy-text h1 {
  text-align: center;
  margin-bottom: 2rem;
  padding-top: 4%;
  padding-bottom: 2%;
}

.privacy-text h2 {
  text-align: left;
  margin-bottom: 2rem;
  margin-left: 2rem;
}

.privacy-text p {
  text-align: justify;
}

.privacy-text_topics {
  font-size: 1.5rem;
  margin-right: 1rem;
}

@media screen and (max-width: 812px) {
  .privacy-mobile {
    margin: 2rem 2rem 0 2rem;
  }
  .content-footer_google {
    display: none;
  }
}

/* NAVBAR */

.nav-bar {
  height: 100%;
  box-shadow: 0px 4px 4px rgb(152 178 255 / 25%);
}

.hr {
  width: 50px;
  height: 0px;
  text-align: center;
  border: 2px solid #686096;
}
.feature_img {
  width: 92%;
  -webkit-box-shadow: 0 10px 40px -5px rgba(130, 34, 210, 0.75);
  box-shadow: 0 10px 40px -5px rgba(130, 34, 210, 0.75);
}

#btn-mobile {
  display: none;
  background: none;
  border-width: 0px;
  border-top: 2px solid #000;
  width: 20px;
  padding-left: 0px;
  margin-left: 16%;
}

#btn-mobile::after,
#btn-mobile::before {
  content: "";
  display: block;
  width: 20px;
  height: 2px;
  background: #7367f0;
  margin-top: 5px;
  padding-left: 16%;
}

#btn-mobile {
  display: none;
  background: none;
  border-width: 0px;
  border-top: 2px solid #7367f0;
  width: 20px;
  padding-left: 0px;
  position: absolute;
  right: 40px;
  top: 15px;
}

@media (max-width: 650px) {
  #check:checked ~ ul {
    left: 0;
  }
  #featherIcon {
    display: block;
    margin-left: 18%;
  }
  #btn-mobile {
    display: block;
  }
  #dropdown-grouped {
    padding-right: 25%;
  }
  .nav-menu ul li {
    padding: 10px;
  }

  .nav-logo {
    display: inline-flex;
    justify-content: center;
  }
  .nav-menu ul {
    display: none;
  }

  .nav-bar-items {
    position: fixed;
    z-index: 100;
    color: #fff;
    top: 0;
    width: 50%;
    height: 100%;
    transition: 0.4s;
    box-shadow: 0px 4px 4px rgba(22, 29, 49, 0.25);
    background: #161d31;
    right: 0;

    transition: 0.4s;

    padding-top: 10%;
  }

  .nav-bar-items ul {
    display: flex;
    flex-direction: column;
    row-gap: 2.5rem;
    padding-top: 2.5rem;
  }

  .nav-bar-items ul li a {
    padding: 0px;
  }

  #featherIcon {
    position: absolute;
    top: 0.75rem;
    left: -3rem;
    right: 1rem;
    cursor: pointer;
  }
  .card-benefics {
    margin: 2px;
  }

  .feature-col {
    margin: none;
    text-align: center;
  }
  .feature-row {
    justify-content: none;
  }

  .feature-icon-graphics img,
  .feature-icon-produtivity img,
  .feature-icon-time img {
    display: none;
  }
}
.feature-col {
  margin-right: 88px;
}

.feature-row {
  display: flex;
  justify-content: center;
}

.feature {
  padding-top: 10%;
}

.header-logo {
  margin-right: 90rem;
}

.text-primary {
  font-weight: bold;
}
nav {
  padding-top: 10px;
}

.logo {
  padding-left: 1rem;
}

.nav-menu ul {
  list-style: none;
}

.nav-menu ul li {
  display: inline-block;
  padding-right: 1rem;
}

.nav-menu_text {
  font-size: 16px;
  color: #7367f0;
}

.nav-menu_login {
  /* background-image: linear-gradient(
    160deg,
    #ffd9ff 0,
    #efbdff 25%,
    #b99df2 50%,
    #897dc0 75%,
    #626192 100%
  ); */

  border-radius: 5rem 3rem 1rem 10rem;
  color: #7367f0;
  font-weight: 600;
}

.content-slider {
  width: 50%;
  margin: 0 auto;
  border-radius: 0rem 0rem 0rem 0rem;
  -webkit-box-shadow: 0 10px 40px -5px rgba(130, 34, 210, 0.75);
  box-shadow: 0 10px 40px -5px rgba(130, 34, 210, 0.75);
}

.dropdown-language {
  right: 0px;
  padding-right: 0px;
}

.content-img {
  width: auto;
}

.content-feature h2,
.title-benefics,
.title-strategy {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 30px;
  color: #686096;
  padding-bottom: 20px;
}

.feature-row {
  padding-top: 5%;
}
.feature_text {
  font-style: normal;
  font-size: 15px;
}

.content-feature_text {
  margin: 2rem 20rem 5rem 20rem;
}
/* .content-feature_analytics {
  display: inline-block;
} */

.content-feature_img {
  width: 40vw;
  -webkit-box-shadow: 0 10px 40px -5px rgba(130, 34, 210, 0.75);
  box-shadow: 0 10px 40px -5px rgba(130, 34, 210, 0.75);

  float: right;
  margin: 0 20rem 5rem 0;
}

.content-feature_icons {
  color: #f8efed;
  background-color: #686096;
  border-radius: 3rem 12rem 12rem 5rem;
  width: 2.5rem;
  height: 2.3rem;
  margin: -0.5rem 1rem 0 0;
}

.content-feature_device {
  width: 50%;
  margin-top: 5rem;
}
.content-feature_deviceBox {
  width: 50%;
  float: right;
  margin-top: -25rem;
}
.content-feature_deviceBox h2 {
  text-align: center;
}
.content-feature_mobile {
  float: right;
  margin: -5rem 18rem 0 0;
}
.content-feature_desktop {
  float: right;
  margin-right: 18rem;
}

@media screen and (min-width: 250px) and (max-width: 812px) {
  .header {
    display: list-item;
  }

  .header-logo {
    display: flex;
    margin-right: auto;
  }

  .header-logo_mobile {
    margin: 2rem 0 2rem 0;
    text-align: center;
    display: block ruby;
  }

  .content-text {
    margin-left: 2rem;
  }

  /* 
  .content-slider {
    width: 100vw;
    height: 40vh;
    margin: 3vw !important;
} */
  .swiper-slide .img-fluid {
    width: 150vw;
  }

  .nav-menu {
    justify-content: center;
  }

  .content {
    margin: 0 2rem -10rem 2rem;
  }

  .content-feature {
    margin-top: 10rem !important;
  }

  .content-feature_text {
    text-align: justify;
    line-height: 1.8rem;
    margin: 0 0 2rem 0;
  }

  .content-feature_img {
    float: initial;
    margin: 0 !important ;
  }

  .content-feature_topics {
    width: 90vw !important;
    margin-top: 2rem;
  }
  .feature-responsive {
    margin: 2rem 0 2rem 2rem;
  }

  .feature-responsive h3 {
    margin-left: -3rem;
  }

  .content-feature_analytics p {
    margin-top: 2rem;
  }

  .content-feature_deviceBox {
    width: auto;
    float: none;
    margin: 2rem 0 2rem 0;
  }
  .content-feature_carrousel {
    display: inline;
    vertical-align: middle;
    margin-left: 20rem;
  }

  .content-feature_mobile,
  .content-feature_desktop {
    float: none;
    margin-left: 2rem;
  }
  .content-feature_mobileBox {
    width: 100vw;
  }

  .content-contact_address {
    margin-left: 0 auto;
    text-align: left;
  }

  .content-google_maps {
    width: 50% !important;
    height: 250px !important;
    margin-left: 2rem !important;
  }
  .content-contact {
    height: auto;
    width: auto;
    display: block;
  }
}

.hr-benefics {
  width: 50px;
  height: 0px;
  text-align: center;
  border: 2px solid #686096;
}

.benefics-area {
  padding-top: 8%;
}

.benefics-area h2 {
  text-align: start;
  margin-left: 10%;
}

.benefics {
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 2%;
}
.card-benefics {
  margin-right: 2%;
  width: 337px;
  height: 170px;
  box-shadow: 0px 4px 4px 2px rgba(0, 0, 0, 0.25);
}

.card-benefics-active {
  margin-right: 2%;
  width: 337px;
  height: 170px;
  background: #686096;
  box-shadow: 0px 4px 4px 2px rgba(0, 0, 0, 0.25);
}

.card-benefics p {
  text-align: center;
  color: #444343;
  font-weight: 100;
  padding-right: 25px;
  padding-left: 25px;
}
.card-benefics-active p {
  text-align: center;
  color: #fff;
  font-weight: 100;
  padding-right: 25px;
  padding-left: 25px;
}

.brand-text-pp {
  /* margin-bottom: -4rem; */
  margin-left: 4px;
  margin-top: -11px;
  font-size: 10px;
  color: #d5d5d5;
}

.r-text {
  margin-left: 3px;
  margin-top: -1px;
  font-size: 20px;
}

.card-benefics h5 {
  padding-top: 13px;
  text-align: center;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 30px;
  /* or 188% */

  color: #353535;
}

.card-benefics-active h5 {
  padding-top: 13px;
  text-align: center;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 30px;
  /* or 188% */

  color: #fff;
}

.section-pages {
  padding-top: 8%;
  padding-bottom: 5%;
}

.section-pages .row {
  padding-top: 50px;
}

.pages-context h5 {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  padding-bottom: 20px;
  color: #fff;
}

.pages-context {
  width: 44rem;
  text-align: start;
  /* display: flex; */
  margin: 0 auto;
}

.section-grapics h5 {
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 36px;
  text-align: center;
  color: #fff;
  padding-bottom: 20px;
}

.section-grapics p {
  width: 1211px;
  font-style: normal;
  font-weight: 100;
  line-height: 24px;
  margin: 0 auto;
}
</style>
